import dayjs from 'dayjs';

export function toDateInputValue(date) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function toDateTimeInputValue(date) {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
}

export function toISOString(date) {
  return dayjs(date).toISOString();
}

export function toDisplayDate(date, options = {}) {
  return dayjs(date).format(
    options.displayTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'
  );
}

export function toMonthAndYear(date) {
  return date.toLocaleDateString('fr-FR', {
    month: 'long',
    year: 'numeric',
  });
}

export function toYmd(date) {
  return (
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2)
  );
}
